import React from 'react'
import styled from 'styled-components'

const Reports = () => {
  return (
    <Wrapper>
    <Title id='sprawozdania'>Sprawozdania roczne</Title>
      <ListItem><a target='_blank' rel='noreferrer' href='https://prb.pl/wp-content/uploads/2024/07/Sprawozdanie_Fundacji_PRB_2022.pdf'>Sprawozdanie 2022</a></ListItem>
    </Wrapper>
  )
}

export default Reports

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  font-size: 18px;
  width: 100%;
  color: ${({ theme }) => theme.colors.secondary};
  letter-spacing: 0.38px;
`
const Title = styled.div`
  font-size: 24px;
  font-weight: 700;
`
const ListItem = styled.li`
  padding-left: 20px;
  color: ${({ theme }) => theme.colors.secondary};

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.secondary};

    &:hover {
      text-decoration: underline;
      color: ${({ theme }) => theme.colors.primary};
    }
  }
  
`
